import "@rmwc/grid/styles"
import { Grid as RMWCGrid } from "@rmwc/grid"
import styled from "styled-components"
import { breakpoints } from "../styles/breakpoints"

export { GridCell, GridRow } from "@rmwc/grid"

export const Grid = styled(RMWCGrid)`
  &.mdc-layout-grid {
    padding-left: var(--gutter-2);
    padding-right: var(--gutter-2);
  }

  @media (max-width: ${breakpoints.SMALL_PHONE}) {
    &.mdc-layout-grid {
      padding-left: var(--gutter-3);
      padding-right: var(--gutter-3);
    }
  }
`

export const GridMainContent = styled(Grid)`
  &.mdc-layout-grid {
    padding-top: var(--gutter-1);
    padding-bottom: var(--gutter-1);
  }

  @media (max-width: ${breakpoints.PHONE}) {
    &.mdc-layout-grid {
      padding-top: var(--gutter-3);
      padding-bottom: var(--gutter-3);
    }
  }
`
