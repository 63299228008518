import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Transition } from "react-transition-group"
import { Typography } from "./typography"
import { Grid, GridCell } from "./grid"
import { Button } from "./button"
import { Wrapper } from "./wrapper"
import LogoSVG from "../assets/incept5-logo.svg"
import SmallLogoSVG from "../assets/incept5-small-logo.svg"
import MenuSVG from "../assets/menu.svg"
import CloseSVG from "../assets/close.svg"
import { breakpoints } from "../styles/breakpoints"
import "./header.css"

const MenuContainer = styled(GridCell)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`

const LogoCell = styled(GridCell)`
  display: flex;
  align-items: center;
  min-height: 45px;
`

const HeaderLogo = styled(LogoSVG)`
  max-height: 30px;
  width: auto;

  @media (max-width: ${breakpoints.PHONE}) {
    display: none;
  }
`

const HeaderLogoSmall = styled(SmallLogoSVG)`
  max-height: 30px;
  width: auto;
  @media (min-width: ${breakpoints.PHONE}) {
    display: none;
  }
`

const NavBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right 0;
  background-color: white;
  z-index: 100;
`

const FixedAdjust = styled.div`
  height: 77px;
  width: 100%;
`

const HeaderGrid = styled(Grid)`
  &.mdc-layout-grid {
    padding-top: var(--mdc-layout-grid-margin-tablet, 16px);
    padding-bottom: var(--mdc-layout-grid-margin-tablet, 16px);
  }
`

const DesktopButton = styled(Button)`
  @media (max-width: ${breakpoints.LARGE_PHONE}) {
    display: none;
  }
`

const TextLink = ({ to, active, children }) => (
  <Link
    to={to}
    css={`
      text-decoration: none;
      overflow: hidden;

      @media (max-width: ${breakpoints.LARGE_PHONE}) {
        display: none;
      }
    `}
  >
    <Typography
      use="body1"
      css={`
        display: inline-block;
        padding-bottom: 2px;
        font-weight: 700;
        border-bottom: 4px solid ${active ? "#31ad99" : "transparent"};

        &:hover {
          border-bottom: 4px solid #31ad99;
        }
      `}
    >
      {children}
    </Typography>
  </Link>
)

const Spacer = styled.span`
  width: 3rem;
`

const Drawer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 200;
  background-color: #653edb;
  padding: 20px var(--gutter-2);
  overflow: auto;

  transform: translateX(
    ${props =>
      props.state === "entering" || props.state === "exiting" ? "100%" : "0%"}
  );
  transition: transform 75ms ease-in-out;
`

const DrawerHeader = ({ onClose }) => (
  <header
    css={`
      display: flex;
      justify-content: flex-end;
    `}
  >
    <CloseSVG
      css={`
        cursor: pointer;
      `}
      onClick={onClose}
    />
  </header>
)

const DrawerList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-top: 100px;
  list-style: none;

  @media (max-height: 600px) {
    margin-top: 50px;
  }
`

const DrawerListItem = ({ to, onClick, active, children }) => (
  <li
    css={`
      margin-bottom: 64px;
    `}
  >
    <Link
      to={to}
      css={`
        text-decoration: none;
        font-size: 35px;
      `}
      onClick={onClick}
    >
      <Typography
        use="headline2"
        css={`
          color: #f9f9fa !important;
          border-bottom: 4px solid ${active ? "#f9f9fa" : "transparent"};
        `}
      >
        {children}
      </Typography>
    </Link>
  </li>
)

export const Header = ({ siteTitle, page }) => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  React.useEffect(() => {
    // Lock body scrolling when the menu is open
    document.body.style.overflow = menuOpen ? "hidden" : "initial"
  }, [menuOpen])

  return (
    <>
      <NavBar>
        <Wrapper>
          <HeaderGrid>
            <LogoCell desktop={2} phone={1} tablet={1}>
              <Link to="/" style={{ display: "flex" }}>
                <HeaderLogo title={siteTitle} />
                <HeaderLogoSmall title={siteTitle} />
              </Link>
            </LogoCell>
            <GridCell desktop={10} phone={3} tablet={7}>
              <MenuContainer>
                <TextLink to="/whitepaper/" active={page === "/whitepaper"}>
                  Whitepapers
                </TextLink>

                <Spacer />

                <TextLink to="/blog/" active={page === "/blog"}>
                  Blog
                </TextLink>

                <Spacer />

                <TextLink to="/about/" active={page === "/about"}>
                  About us
                </TextLink>

                <Spacer />

                {page !== "/contact" && (
                  <DesktopButton
                    label="Contact us"
                    theme="secondary"
                    tag="a"
                    to="/contact/"
                    useGatsbyLink
                  />
                )}

                <MenuSVG
                  onClick={() => {
                    setMenuOpen(true)
                  }}
                  css={`
                    cursor: pointer;
                    display: none;

                    @media (max-width: ${breakpoints.LARGE_PHONE}) {
                      display: block;
                    }
                  `}
                />
              </MenuContainer>
            </GridCell>
          </HeaderGrid>
        </Wrapper>
      </NavBar>

      <Transition in={menuOpen} timeout={75} unmountOnExit>
        {state => {
          return (
            <Drawer state={state}>
              <DrawerHeader onClose={() => setMenuOpen(false)} />

              <DrawerList>
                {[
                  {
                    to: "/",
                    label: "Home",
                  },
                  {
                    to: "/whitepaper/",
                    label: "Whitepapers",
                  },
                  {
                    to: "/blog/",
                    label: "Blog",
                  },
                  {
                    to: "/about/",
                    label: "About us",
                  },
                  {
                    to: "/contact/",
                    label: "Contact us",
                  },
                ].map(({ to, label }) => (
                  <DrawerListItem
                    key={to}
                    to={to}
                    active={page === to}
                    onClick={() => setMenuOpen(false)}
                  >
                    {label}
                  </DrawerListItem>
                ))}
              </DrawerList>
            </Drawer>
          )
        }}
      </Transition>

      <FixedAdjust />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  page: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
