import React from "react"
import { Typography } from "@rmwc/typography"
import styled from "styled-components"
import { Wrapper } from "./wrapper"
import { GridMainContent, GridCell } from "./grid"
import { breakpoints } from "../styles/breakpoints"

const GridCellCenteredContents = styled(GridCell)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const CenteredTitle = styled(Typography)`
  &.mdc-typography--body1 {
    color: var(--text-color-light);
  }

  text-align: center;

  @media (max-width: ${breakpoints.PHONE}) {
    display: block;
    font-size: 14px;
  }
`

export function Footer() {
  return (
    <Wrapper>
      <GridMainContent>
        <GridCellCenteredContents span={12}>
          <CenteredTitle use="body1">
            Copyright © 2024 Incept5 Ltd. All rights reserved.
          </CenteredTitle>
        </GridCellCenteredContents>
      </GridMainContent>
    </Wrapper>
  )
}
