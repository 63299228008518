import "@rmwc/typography/styles"
import styled from "styled-components"
import { Typography as RMWCTypography } from "@rmwc/typography"
import { breakpoints } from "../styles/breakpoints"

export const Typography = styled(RMWCTypography)`
  margin: 0;

  &[class*="mdc-typography"] {
    color: var(--text-color);
  }

  @media (max-width: ${breakpoints.TABLET}) {
    &.mdc-typography--headline1 {
      line-height: var(--mdc-typography-headline2-line-height);
      font-size: var(--mdc-typography-headline2-font-size);
    }

    &.mdc-typography--headline2 {
      font-size: var(--mdc-typography-headline3-font-size);
    }

    &.mdc-typography--subtitle1 {
      font-size: var(--mdc-typography-body1-font-size);
    }
  }

  @media (max-width: ${breakpoints.PHONE}) {
    &.mdc-typography--headline2 {
      line-height: 1.2;
    }
  }

  &.mdc-typography--subtitle1,
  &.mdc-typography--body1 {
    color: var(--text-color-light);
  }
`
