import { TextField as RMWCTextField } from "@rmwc/textfield"
import styled from "styled-components"
// Copied to relative path because of build issue where css is not included in build
import "./mdc.textfield.min.css"
import "@material/floating-label/dist/mdc.floating-label.css"
import "@material/notched-outline/dist/mdc.notched-outline.css"
import "@material/line-ripple/dist/mdc.line-ripple.css"
import "@material/ripple/dist/mdc.ripple.css"
import "@rmwc/icon/icon.css"

export const TextField = styled(RMWCTextField)`
  &.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
    .mdc-notched-outline__leading,
  &.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
    .mdc-notched-outline__notch,
  &.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
    .mdc-notched-outline__trailing {
    border-color: var(--bg-light-2);
    border-width: 2px;
  }

  &.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):not(.mdc-text-field--focused)
    .mdc-floating-label {
    color: var(--text-color-light);
  }
  /* Fix field text color */
  &.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):not(.mdc-text-field--focused)
    .mdc-text-field__input {
    color: var(--text-color-light);
  }

  .mdc-floating-label,
  .mdc-text-field__input {
    line-height: 1.2;
    font-family: var(--mdc-typography-font-family);
    font-size: var(--mdc-typography-body1-font-size);
  }
`
