import React from "react"
import { InfoBlock } from "./info-block"
import { Wrapper } from "./wrapper"
import { Grid, GridRow, GridCell } from "./grid"
import { Typography } from "./typography"
import { breakpoints } from "../styles/breakpoints"

export const Info = ({ heading, subHeading, image }) => (
  <InfoBlock>
    <Wrapper>
      <Grid>
        <GridCell
          desktop={7}
          tablet={5}
          phone={4}
          css={`
            padding-top: calc(
              var(--gutter-1) - var(--mdc-layout-grid-margin-desktop)
            );
            padding-bottom: calc(
              var(--gutter-1) - var(--mdc-layout-grid-margin-desktop)
            );

            @media (max-width: ${breakpoints.PHONE}) {
              order: 2;
              padding-top: 0;
              padding-bottom: 0;
            }
          `}
        >
          <GridRow
            css={`
              margin-bottom: var(--mdc-layout-grid-margin-desktop);
            `}
          >
            <GridCell span={12}>
              <Typography
                tag="h1"
                use="headline1"
                css={`
                  @media (max-width: ${breakpoints.PHONE}) {
                    text-align: center;
                  }
                `}
              >
                {heading}
              </Typography>
            </GridCell>
          </GridRow>
          <GridRow>
            <GridCell span={12}>
              <Typography tag="h2" use="subtitle1">
                {subHeading}
              </Typography>
            </GridCell>
          </GridRow>
        </GridCell>
        <GridCell
          desktop={5}
          tablet={3}
          phone={4}
          css={`
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: ${breakpoints.PHONE}) {
              order: 1;
            }
          `}
        >
          {image}
        </GridCell>
      </Grid>
    </Wrapper>
  </InfoBlock>
)
